import logo from './logo.svg';
import React from 'react'
import {Routes, Route} from 'react-router-dom';
import LayoutFrontend from './components/public/layout/index';
import Home from './components/public/pages/Home';
import Error from './components/public/pages/Error';
import About from './components/public/pages/About';
import Contact from './components/public/pages/Contact';
import Services from './components/public/pages/Services';
import Products from './components/public/pages/Products';


function App() {
  return (
      <div className="App">
          <Routes>
              { <Route path="/" element={<LayoutFrontend />}>
                    <Route index element={<Home />}/>
                    <Route path="/about" element={<About />}/>
                    <Route path="/services" element={<Services />}/>
                    <Route path="/products" element={<Products />}/>
                    <Route path="/contact" element={<Contact />}/>
              </Route> }
              <Route path="*" element={<Error />} />
          </Routes>
      </div>
  );
}

export default App;
