
import React, {useEffect}  from 'react';
import { Link } from 'react-router-dom';
import Footer from '../common/Footer';
import Header from '../common/Header';
import {Helmet} from "react-helmet";
import AOS from "simple-aos";


export const Home = () => {  

  useEffect(() => {    
    AOS.init({
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: true, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom"
    });
  });

    return (
    <React.Fragment>    
      <Helmet>
        <title>Exploreby Technology</title>
        <meta name="title" content="Grow your business using Blockchain" />
        <meta name="description" content="Blockchain Tech company that helps your businesses to prepare for the new wave of the economy." />
        <meta name="keywords" content="exploreby, blockchain, information, technology, development, IT services, cryptocurrency, nft, marketplace, defi, dapps, smartcontract, ethereum, decentralize, application, global, uae, dubai" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English"></meta>
      </Helmet>  
      <Header sticky={true}/>           
      <div>     
      
        <section id="demo" className="bg-white pt-40 pb-10 relative">       
        <div>
          <div className="container m-auto px-10 md:px-0">
              <div className="max-w-2xl m-auto text-center">
                <h2 className="text-6xl font-bold text-gray-700 mb-5" data-aos="fade-down">Grow your business using <span className="text-indigo-600">Blockchain</span></h2>
                <h5 className="text-xl mt-2" data-aos="fade-up">Exploreby Technology - a Blockchain Tech company that helps your businesses to prepare for the new wave of the economy.</h5>
                <div className="text-center mb-10">
                <Link to="./contact" className="inline-block mt-5 bg-indigo-600 text-white px-4 py-2 rounded-full">Let's Start</Link>
                </div>           
              </div>
              <div className='text-center'>
                <div className='mt-20 flex flex-wrap md:flex-row gap-x-4 md:gap-x-10 gap-y-5 justify-center' data-aos="fade-up">    
                    <img src='/assets/img/icons/blockchain/ethereum.png' className='grayscale hover:grayscale-0 h-10 w-10 md:h-16 md:w-16'/>         
                    <img src='/assets/img/icons/blockchain/binance.png'  className='grayscale hover:grayscale-0 h-10 w-10 md:h-16 md:w-16'/>
                    <img src='/assets/img/icons/blockchain/hyperledger.png'  className='grayscale hover:grayscale-0 h-10 w-10 md:h-16 md:w-16'/>
                    <img src='/assets/img/icons/blockchain/polygon.png'  className='grayscale hover:grayscale-0 h-10 w-10 md:h-16 md:w-16'/>
                    <img src='/assets/img/icons/blockchain/cardano.png'  className='grayscale hover:grayscale-0 h-10 w-10 md:h-16 md:w-16'/>
                    <img src='/assets/img/icons/blockchain/ripple.png'  className='grayscale hover:grayscale-0 h-10 w-10 md:h-16 md:w-16'/>
                    <img src='/assets/img/icons/blockchain/steller.png'  className='grayscale hover:grayscale-0 h-10 w-10 md:h-16 md:w-16'/>
                    <img src='/assets/img/icons/blockchain/solana.png'  className='grayscale hover:grayscale-0 h-10 w-10 md:h-16 md:w-16'/>

                  </div>
              </div>
          </div>
        </div>
        
        </section>

        <section className="bg-indigo-50 mt-0">
          <div className="grid grid-cols-1 md:grid-cols-2 px-6 xl:px-20 py-4 sm:gap-x-10 md:gap-x-20 lg:pt-12 lg:pb-6">
            <div className="flex items-center rounded-md">
              <div className="h-auto outline-none text-right">
                <h3 className="text-3xl md:text-6xl font-bold one">One-Stop Solution for Metaverse, NFT, DeFi, and Cryptocurrency </h3>
                <h5 className="text-xl mt-5">We provide full-stack solutions ranging from Development to Marketing and PR in the blockchain and cryptocurrencies. </h5>
                <Link to="./contact" target="_new" className="inline-block mt-5 bg-indigo-600 text-white px-4 py-2 rounded-full">Let's Start</Link>
              </div>
            </div>
            <div className="hidden md:flex md:flex-col sm:pr-10">
              <img src="assets/img/svg/stats.svg" className="w-5/6 mt-5"/>
            </div>
          </div>
          <div className="pt-12 pb-16 px-6 md:px-20" data-aos="fade-up">
            <div className="grid grid-cols-2 gap-8 xl:gap-4 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5">
              <div className="p-4 space-y-3 bg-white rounded-xl flex items-center">
                  <div className="flex flex-col md:flex-row gap-x-4 gap-y-4">
                    <div className="w-2/5"><img src="./assets/img/icons/client.png" className="w-4/5" /></div>
                    <div>
                      <span className="text-2xl font-medium">25+</span>
                      <p>clients served</p>
                    </div>
                  </div>
              </div>
              <div className="px-4 md:px-6 py-6 space-y-3 bg-white rounded-xl">
                <div className="flex flex-col md:flex-row gap-x-4 gap-y-4">
                  <div className="w-2/5"><img src="assets/img/icons/users.png"/></div>
                  <div>
                    <span className="text-xl font-medium">2,000,000+ </span>
                    <p>Users onboarded</p>
                  </div>
                </div>
              </div>
              <div className="p-6 space-y-3 bg-white rounded-xl">
                <div className="flex flex-col md:flex-row gap-x-4 gap-y-4">
                  <div className="w-2/5"><img src="./assets/img/icons/blockchain.png"/></div>
                  <div>
                    <span className="text-xl font-medium">1200+ </span>
                    <p>Blockchain Protocol Explored</p>
                  </div>
                </div>
              </div>
              <div className="p-6 space-y-3 bg-white rounded-xl">      
                <div className="flex flex-col md:flex-row gap-x-4 gap-y-4">
                  <div className="w-2/5"><img src="./assets/img/icons/finance.png"/></div>
                  <div>
                    <span className="text-xl font-medium">100+ </span>
                    <p>DeFi protocol Explored</p>
                  </div>
                </div>
              </div>
              <div className="p-8 space-y-3 bg-white rounded-xl"> 
                <div className="flex flex-col md:flex-row gap-x-4 gap-y-4">
                  <div className="w-2/5"><img src="./assets/img/icons/nft.png"/></div>
                  <div>
                    <span className="text-xl font-medium">10+ </span>
                    <p>Protocol Explored</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-indigo-300 mt-0 py-20 px-6 md:px-20 border-indigo-300">
          <div className="flex flex-col md:flex-row gap-x-20">
            <div className="w-full md:w-5/12 text-lg flex flex-col gap-y-20">
                <div>
                  <span>Areas we contribute?</span>
                  <p className="text-5xl font-bold mt-4 tracking-tight">We can help with a wide range of support on all <span className="text-indigo-600">blockchain</span> products.</p>
                </div>
                <img src="assets/img/svg/help.svg" className='w-full' alt='' data-aos="fade-up"/>
            </div>   
                     
            <div className="w-full md:w-7/12 masonry-2-col gap-x-4 md:gap-x-8 gap-y-8 text-slate-900">
              
              <div className="break-inside border my-5 p-3 md:p-6 space-y-3 bg-indigo-100 border-b-1 border-gray-400 dark:border-gray-300 shadow-lg rounded-md" data-aos="fade-down">
                <div className="flex gap-x-4">
                  <div className='hidden md:block md:w-3/12'><img src="./assets/img/icons/blockchain.png"/></div>
                  <div className='w-9/12'>
                    <span className="block text-lg md:text-2xl font-bold pb-2">Blockchain</span>
                    <div>
                      <ul>
                        <li>Defi Product Development</li>
                        <li>Decentralized Application Development</li>
                        <li>Smart Contract</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> 
              
              <div className="break-inside border my-5 p-3 md:p-6 space-y-3 bg-indigo-100 border-b-1 border-gray-400 dark:border-gray-300 shadow-lg rounded-md" data-aos="fade-down">
                <div className="flex gap-x-4">
                  <div className='hidden md:block md:w-3/12'><img src="./assets/img/icons/payment.png"/></div>
                  <div className='w-9/12'>
                    <span className="block text-lg md:text-2xl font-bold pb-2">Banking and Payment Solution </span>
                    <div>
                      <ul>
                        <li>Crypto Wallet Development</li>
                        <li>Payment Gateway </li>
                        <li>Cross Border Payment solution</li>
                        <li>Digital KYC</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> 

              <div className="break-inside border my-5 p-3 md:p-6 space-y-3 bg-indigo-100 border-b-1 border-gray-400 dark:border-gray-300 shadow-lg rounded-md" data-aos="fade-up">
                <div className="flex gap-x-4">
                  <div className='hidden md:block md:w-3/12'><img src="./assets/img/icons/ecommerce.png"/></div>
                  <div className='w-9/12'>
                    <span className="block text-lg md:text-2xl font-bold pb-2">Government</span>
                    <div>
                      <ul>
                        <li>Digital Currency</li>
                        <li>Voting System</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="break-inside border my-5 p-3 md:p-6 space-y-3 bg-indigo-100 border-b-1 border-gray-400 dark:border-gray-300 shadow-lg rounded-md" data-aos="fade-up">
                <div className="flex gap-x-4">
                  <div className='hidden md:block md:w-3/12'><img src="./assets/img/icons/travel.png"/></div>
                  <div className='w-9/12'>
                    <span className="block text-lg md:text-2xl font-bold pb-2">Travel and Tourism</span>
                    <div>
                      <ul>
                        <li>Flight booking platform</li>
                        <li>Hotel booking platform</li>
                        <li>Travel Activity booking platform</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> 

              <div className="break-inside border my-5 p-3 md:p-6 space-y-3 bg-indigo-100 border-b-1 border-gray-400 dark:border-gray-300 shadow-lg rounded-md" data-aos="fade-up">
                <div className="flex gap-x-4">
                  <div className='hidden md:block md:w-3/12'><img src="./assets/img/icons/ecommerce.png"/></div>
                  <div className='w-9/12'>
                    <span className="block text-lg md:text-2xl font-bold pb-2">Retail & Ecommerce</span>
                    <div>
                      <ul>
                        <li>Marketplace Platform</li>
                        <li>Payment Gateway </li>
                        <li>Cross Border Payment solution</li>
                        <li>Digital KYC</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
               

          </div>
        </div>
        </section>
        
      </div>
    
      
      <Footer />
    </React.Fragment>

    );
}

export default Home;
