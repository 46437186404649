import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="mt-0 py-10 px-10 md:px-20 bg-indigo-500 border-b border-indigo-400">
            <div className="max-w-xl m-auto mt-5 text-center">
              <div>
                  <span className="text-xl text-indigo-200">Planning for blockchain?</span>
                  <p className="text-3xl font-medium mt-2 text-white">We can help with a wide range of support on all blockchain products.</p>
                  <Link to="/contact" className="inline-block mt-5 bg-indigo-600 text-white px-4 py-2 rounded-full">Let's Start</Link>

                </div>
            </div>
        </section>
        <footer id="footer" className="bg-indigo-500 py-8">
          <div className="md:container m-auto py-8 px-6 md:px-32">
            <div className="flex flex-col md:flex-row gap-x-6">
              
              <div className="w-full md:w-1/2 lg:w-5/12 wow fadeInUp" data-wow-delay="0.2s">
                <div className="mx-3 mb-8">
                  <h4 className="text-xl font-semibold text-white mb-2">Exploreby Technology</h4>
                  <p className="text-white">Exploreby Technology is a 360 degree blockchain consulting company. We deliver performance-driven solutions that are guaranteed to meet your needs and give you that competitive <edge className=""></edge></p>
                </div>
              </div>

              <div className="w-full sm:w-1/2 md:w-1/2 lg:w-2/12 wow fadeInUp" data-wow-delay="0.4s">
                <div className="mx-3 mb-8">
                  <h3 className="font-bold text-xl text-white mb-5">Company</h3>
                  <ul className="text-white">
                    <li><Link to="/about" className="footer-links">About Us</Link></li>
                    <li><Link to="/services" className="footer-links">Services</Link></li>
                    <li><Link to="/products" className="footer-links">Products</Link></li>
                    <li><Link to="#" className="footer-links">Career</Link></li>
                  </ul>
                </div>
              </div>
              
              <div className="w-full sm:w-1/2 md:w-1/2 lg:w-2/12 wow fadeInUp" data-wow-delay="0.8s">
                <div className="mx-3 mb-8">
                  <h3 className="font-bold text-xl text-white mb-5">Find us on</h3>
            
                  <div className="flex gap-x-2">
                    <Link to="/contact"><SocialIcon network="email" bgColor="#ffffff" style={{ height: 32, width: 32}} /></Link>
                    <SocialIcon network="twitter" fgColor="#ffffff" style={{ height: 32, width: 32 }} />
                    <SocialIcon network="medium" fgColor="#ffffff" style={{ height: 32, width: 32 }} />
                    </div>
                </div>
              </div>

            </div>
          </div>     
        </footer> 
      </React.Fragment>
    );
  }
}

export default Footer;
