import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {SocialIcon} from 'react-social-icons'

class Header extends Component {

  constructor(props) {
    super(props);

    console.log(props);
    
    this.state = {
      sticky: false,
      mobileMenu:false
    }   
    
    this.handleMobileMenu = this.handleMobileMenu.bind(this);

  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleMobileMenu = e => {

    console.log(this.state.mobileMenu);

    this.setState({ mobileMenu: !this.state.mobileMenu });
    
  }


  handleScroll = e => {
    
    if(this.props.sticky===true) {
      const header_navbar =  document.querySelector(".navigation");
      if (window.scrollY > 1) { 
        ['bg-white','border-b','fixed','top-0','left-0'].forEach(item => header_navbar.classList.add(item));
      }
      else {
        ['bg-white','border-b'].forEach(item => header_navbar.classList.remove(item));
      }
    }
  }

  render() {
    return (
      <React.Fragment>
          <header id="header-wrap" className="relative">
          <div className={`w-full py-2 z-30 duration-300${this.props.sticky===false?" bg-white border-b":" navigation fixed top-0 left-0"}`}>
            <div className="lg:container m-auto">
              <nav className="navbar py-2 navbar-expand-lg flex justify-between items-center relative duration-300">
                <div className='pl-6 lg:pl-0'>
                    <Link to="/" className="flex gap-x-2 items-center">
                      <img src="/assets/img/logo_dark.png" className="h-8 w-auto"/>
                      <span className="text-md font-light">EXPLOREBY <b className="text-white-500 font-medium">TECHNOLOGY</b></span>
                    </Link>
                </div>
                <div className={`${this.state.mobileMenu ? 'absolute z-10 left-0 right-0 top-12 px-6 py-4 bg-white shadow-md flex flex-col gap-y-3':'hidden'} md:static md:py-0 md:px-0 md:top-0 md:border-0 md:flex md:flex-row gap-x-4`}>
                    <Link to="/">Home</Link>
                    <Link to="/about">About</Link>
                    <Link to="/services">Services</Link>
                    <Link to="/products">Products</Link>
                    <Link to="/contact">Contact</Link>
                </div>
                <div className='pr-6 lg:pr-0'>
                    <div className="hidden md:flex gap-x-2">
                      <Link to="/contact"><SocialIcon network="email" style={{ height: 25, width: 25 }} /></Link>
                      <SocialIcon network="twitter" style={{ height: 25, width: 25 }} />
                      <SocialIcon network="medium" style={{ height: 25, width: 25 }} />
                    </div>
                    <button className="inline-block md:hidden w-8 h-8 text-gray-600 p-1" onClick={this.handleMobileMenu}>
                        <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                    </button>
                </div>
              </nav>
            </div>
          </div>
          </header>
      </React.Fragment>
    );
  }
}

export default Header;
