import React, { Component } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';




export default class Products extends Component {
  render() {
    return (
      <React.Fragment>
         <Helmet>
          <title>Exploreby Technology:: Products</title>
          <meta name="title" content="Product of Exploreby Technology" />
          <meta name="description" content="We develop blockchain products that power your business and deliver a better future." />
          <meta name="keywords" content="exploreby, blockchain, information, technology, development, IT services, cryptocurrency, nft, marketplace, defi, dapps, smartcontract, ethereum, decentralize, application, global, IT products, uae, dubai" />
          <meta name="robots" content="index, follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English"></meta>

        </Helmet> 
        <Header sticky={false}/>
        <section id="hero" className='bg-green-600 text-white w-full px-6 md:px-10 lg:px-40 py-10 md:py-20'>          
          <div className="flex flex-col md:flex-row gap-x-4">
            <div className="md:w-3/12 mb-2">
              <div className="flex flex-row md:flex-col gap-x-1 text-2xl uppercase">
              <span className='font-bold md:font-normal'>Explore</span>
              <span className="text-2xl lg:text-3xl font-bold text-indigo-50">Our Products</span>
              </div>
            </div>
            <div className='md:w-8/12 lg:w-8/12 xl:w-9/12 flex flex-col'>
              <h1 className='text-xl md:text-2xl lg:text-3xl font-regular md:font-semibold'>We develop blockchain products that power your business and deliver a better future.</h1>
              <div className="wow fadeInUp" data-wow-delay="1.2s">
              <Link to="./contact" className="inline-block mt-5 bg-indigo-50 text-indigo-600 px-4 py-2 rounded-full">Let's Start</Link>
              </div>
            </div>
            
          </div>
        </section>
        <section className='py-10 md:py-20 px-6 lg:px-16 xl:px-40 bg-white'>
            <div className="grid grid-cols-3 gap-x-4  md:gap-x-20 gap-y-10 justify-between">
                    
                    <div className="flex flex-col items-center md:flex-row gap-y-4 gap-x-6 lg:gap-x-3">                    
                        <div className="md:w-3/12">
                            <div className="w-16 h-16 rounded-full flex items-center justify-center">
                                <img src="./assets/img/icons/ecommerce.png" />
                            </div>
                        </div>
                        <div className="w-9/12 text-center md:text-left text-md md:text-2xl font-medium">Ecommerce Marketplace</div>
                    </div>

                    <div className="flex flex-col items-center md:flex-row gap-y-4 gap-x-6 lg:gap-x-3">                    
                        <div className="md:w-3/12">
                            <div className="w-16 h-16 rounded-full flex items-center justify-center">
                                <img src="./assets/img/icons/travel.png"/>
                            </div>
                        </div>
                        <div className="w-9/12 text-center md:text-left text-md md:text-2xl font-medium">Travel Booking Platform</div>
                    </div>

                    <div className="flex flex-col items-center md:flex-row gap-y-4 gap-x-6 lg:gap-x-3">                    
                        <div className="md:w-3/12">
                            <div className="w-16 h-16 rounded-full flex items-center justify-center">
                                <img src="./assets/img/icons/decentralized.png" />
                            </div>
                        </div>
                        <div className="w-9/12 text-center md:text-left text-md md:text-2xl font-medium">Decentralized Exchange</div>
                    </div>
                    
                    <div className="flex flex-col items-center md:flex-row gap-y-4 gap-x-6 lg:gap-x-3">        
                        <div className="md:w-3/12">
                        <div className="w-16 h-16 rounded-full flex items-center justify-center">
                            <img src="./assets/img/icons/nft.png"/></div>
                        </div>
                        <div className="w-9/12 text-center md:text-left text-md md:text-2xl font-medium">NFT Marketplace</div>
                    </div>
                    <div className="flex flex-col items-center md:flex-row gap-y-4 gap-x-6 lg:gap-x-3">
                        <div className="md:w-3/12">
                            <div className="w-16 h-16 rounded-full flex items-center justify-center">
                            <img src="./assets/img/icons/lending.png" className="w-4/5" /></div>
                        </div>
                        <div className="w-9/12 text-center md:text-left text-md md:text-2xl font-medium">DeFi Lending and Borrowing Platform</div>
                    </div>
                    <div className="flex flex-col items-center md:flex-row gap-y-4 gap-x-6 lg:gap-x-3">
                        <div className="md:w-3/12">
                            <div className="w-16 h-16 rounded-full flex items-center justify-center">
                            <img src="./assets/img/icons/wallet.png" /></div>
                        </div>
                        <div className="w-9/12 text-center md:text-left text-md md:text-2xl font-medium">Crypto Wallet</div>
                    </div>
                    <div className="flex flex-col items-center md:flex-row gap-y-4 gap-x-6 lg:gap-x-3">
                        <div className="md:w-3/12">
                            <div className="w-16 h-16 rounded-full flex items-center justify-center">
                            <img src="./assets/img/icons/ico.png" /></div>
                        </div>
                        <div className="w-9/12 text-center md:text-left text-md md:text-2xl font-medium">Token Presale/ICO Platform</div>
                    </div>
                    <div className="flex flex-col items-center md:flex-row gap-y-4 gap-x-6 lg:gap-x-3">
                        <div className="md:w-3/12">
                            <div className="w-16 h-16 rounded-full flex items-center justify-center">
                            <img src="./assets/img/icons/network.png" /></div>
                        </div>
                        <div className="w-9/12 text-center md:text-left text-md md:text-2xl font-medium">Token Bridge Platform</div>
                    </div> 
                    <div className="flex flex-col items-center md:flex-row gap-y-4 gap-x-6 lg:gap-x-3">
                        <div className="md:w-3/12">
                            <div className="w-16 h-16 rounded-full flex items-center justify-center">
                            <img src="./assets/img/icons/exchange.png" className="w-4/5" /></div>
                        </div>
                        <div className="w-9/12 text-center md:text-left text-md md:text-2xl font-medium">Centralized Exchange</div>
                    </div>      
            </div>
        </section>
        <section className='py-10 md:py-20 px-6 lg:px-16 xl:px-40 bg-gray-600 border-t'>
            <h1 className="text-2xl md:text-3xl font-semibold text-white">Our Approach to Product Implementation</h1>
            <div className="grid md:grid-cols-3 gap-x-10 gap-y-4 mt-10">
                <div class="flex flex-col gap-y-4 p-8 bg-purple-500 text-white">
                    <h3 className="font-bold text-xl md:text-3xl">1. A product tailored to your need</h3>
                    <p className="font-regular text-lg">We know every business is unique, so we don’t deal in cookie-cutter solutions. Our approach is to create blockchain-powered products tailored to your business’ needs.</p>
                </div>
                <div class="flex flex-col gap-y-4 p-8 bg-green-600 text-white">
                    <h3 className="font-bold text-xl md:text-3xl">2. Seamless Integration fit for your technology</h3>
                    <p className="font-regular text-lg">Blockchain is diverse, so we are. We’re not specialized to one specific blockchain technology. It means we make sure our product is ready to use the protocol that’s right for your situation.</p>
                </div>
                <div class="flex flex-col gap-y-4 p-8 bg-blue-900 text-white">
                    <h3 className="font-bold text-xl md:text-3xl">3. Hire us or let us build a team for you</h3>
                    <p className="font-regular text-lg">Whether you prefer to work directly with your consultant, or with a full team; whatever your budget allows for, that’s how we’ll work with you.</p>
                </div>
            </div>
        </section>
        <section className='py-10 md:py-20 px-6 lg:px-16 xl:px-40 bg-gray-50 border-t'>
            <h1 className="text-2xl md:text-3xl font-semibold">Blockchain Platform We are working on</h1>    
            <div className='mt-10 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-9 gap-x-16 gap-y-6'>  

                  <div className='flex flex-col items-center gap-y-2'>
                    <img src='/assets/img/icons/blockchain/ethereum.png' className='grayscale hover:grayscale-0 h-16 w-16'/>
                    <p className='text-center'>Ethereum</p>
                  </div>
                  <div className='flex flex-col items-center gap-y-2'>
                    <img src='/assets/img/icons/blockchain/binance.png'  className='grayscale hover:grayscale-0 h-16 w-16'/>
                    <p className='text-center'>Binance</p>
                  </div>
                  <div className='flex flex-col items-center gap-y-2'>
                    <img src='/assets/img/icons/blockchain/hyperledger.png'  className='grayscale hover:grayscale-0 h-16 w-16'/>
                    <p className='text-center'>Hyperledger</p>
                  </div>
                  <div className='flex flex-col items-center gap-y-2'>
                    <img src='/assets/img/icons/blockchain/polygon.png'  className='grayscale hover:grayscale-0 h-16 w-16'/>
                    <p className='text-center'>Polygon</p>
                  </div>                  
                  <div className='flex flex-col items-center gap-y-2'>
                    <img src='/assets/img/icons/blockchain/cardano.png'  className='grayscale hover:grayscale-0 h-16 w-16'/>
                    <p className='text-center'>Cardano</p>
                  </div>
                  <div className='flex flex-col items-center gap-y-2'>
                    <img src='/assets/img/icons/blockchain/ripple.png'  className='grayscale hover:grayscale-0 h-16 w-16'/>
                    <p className='text-center'>Ripple</p>
                  </div>                  
                  <div className='flex flex-col items-center gap-y-2'>
                    <img src='/assets/img/icons/blockchain/eos.png'  className='grayscale hover:grayscale-0 h-16 w-16'/>
                    <p className='text-center'>EOS</p>
                  </div>
                  <div className='flex flex-col items-center gap-y-2'>
                    <img src='/assets/img/icons/blockchain/solana.png'  className='grayscale hover:grayscale-0 h-16 w-16'/>
                    <p className='text-center'>Solana</p>
                  </div>
                  <div className='flex flex-col items-center gap-y-2'>
                    <img src='/assets/img/icons/blockchain/icon.png'  className='grayscale hover:grayscale-0 h-16 w-16'/>
                    <p className='text-center'>Icon</p>
                  </div>
            </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}
