import React, { Component } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet';



export default class About extends Component {
  render() {
    return (
      <React.Fragment>
         <Helmet>
          <title>Exploreby Technology:: About Company</title>
          <meta name="title" content="About Exploreby Technology" />
          <meta name="description" content="Exploreby Technology is dedicated to growing the blockchain industry." />
          <meta name="keywords" content="exploreby, blockchain, information, technology, development, IT services, cryptocurrency, nft, marketplace, defi, dapps, smartcontract, ethereum, decentralize, application, global, uae, dubai" />
          <meta name="robots" content="index, follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English"></meta>
        </Helmet> 
        <Header sticky={false}/>
        <div className='my-10 md:my-20 px-6 lg:px-16 xl:px-20'>
          
          <div class="flex flex-col md:flex-row gap-x-4">
            <div className="w-full md:w-3/12 mb-2">
              <div class="flex flex-row md:flex-col gap-x-1 text-xl uppercase">
              <span className='font-bold md:font-normal'>About</span>
              <span className="lg:text-3xl font-bold">Exploreby</span>
              <span className="lg:text-3xl font-bold">Technology</span>
              </div>
            </div>
            <div className='md:w-8/12 lg:w-8/12 xl:w-7/12 flex flex-col'>
              <h1 className='text-xl md:text-3xl font-medium md:font-semibold'>Exploreby Technology is dedicated to growing the blockchain industry.</h1>
              
              <div className='mt-10'>
                <h3 className='text-xl font-semibold mb-2'>Who We are</h3>
                <p className='text-justify text-lg'>Exploreby Technology is a 360 degree blockchain consulting company. We deliver performance-driven solutions that are guaranteed to meet your needs and give you that competitive edge.
                  <br /><br />We’re passionate about advancing the industry as a whole, and seeing how this exciting new technology can be adopted to make our everyday lives better. we provide a range of blockchain application development services across various industries that introduce business transparency, security, automation, and efficiency. </p>
              </div>
              
              <div className='mt-10'>
                <h3 className='text-xl font-semibold mb-4'>Who we work with?</h3>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-x-4'>
                  <div className='border p-4 bg-gray-600 text-white'>
                      <h4 className='text-left text-2xl font-bold mb-2'>Startup</h4>
                      <p className='text-xl'>The early-adopters. The digital natives. The businesses that rely on innovative technology to thrive. We help you take advantage of blockchain to power your business forward.</p>
                  </div>
                  <div className='border p-4 bg-blue-800 text-white'>
                    <h4 className='text-left text-2xl font-bold mb-2'>Enterprise & Government</h4>
                    <p className='text-xl'>Enterprise businesses shouldn’t be locked into old-fashioned ways of working. We provide blockchain solutions that are customised for your business, to help you lead your industry.</p>
                  </div>
                  <div className='border p-4 bg-purple-800 text-white'>
                    <h4 className='text-left text-2xl font-bold mb-2'>Investors</h4>
                    <p className='text-xl'>Long-term growth doesn’t come without innovation. See the exciting potential that this emerging technology has to offer, and how you can get on board.</p>
                  </div>
                </div>
              </div>

              <div className='mt-10'>
                <h3 className='text-xl font-semibold mb-2'>Our Expertise</h3>
                <p className='mb-5 text-lg'>At Blockchain, we develop, maintain and evaluate blockchain and cryptocurrency related products.</p>
                <p className='mb-5 text-lg'>Our teams operate in multiple disciplines, from hardware to financial trading frameworks and Blockchain web services, whilst creating beautiful and intuitive UI/UX.</p>
                <p className='mb-5 text-lg'>For our partners worldwide we provide long-term assistance and advice on related technological and operational matters.</p>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-4">
                  <div className='p-4 font-bold bg-blue-700 text-white text-lg'>
                    Design & Development (Web and Mobile app)
                  </div>
                  <div className='p-4 font-bold bg-purple-700 text-white text-lg'>
                  Ecosystem Development
                  </div>
                  <div className='p-4 font-bold bg-green-800 text-white text-lg'>
                  Digital Marketing and PR
                  </div>
                  <div className='p-4 font-bold bg-gray-800 text-white text-lg'>
                  Customer Support Service
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
