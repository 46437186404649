import React, { Component } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export default class Services extends Component {
  render() {
    return (
      <React.Fragment>
         <Helmet>
          <title>Exploreby Technology:: Services</title>
          <meta name="title" content="Exploreby Technology Services" />
          <meta name="description" content="We deliver end-to-end solutions that are guaranteed to meet your needs, drive your performance, and give you that competitive edge." />
          <meta name="keywords" content="exploreby, blockchain, technology, development, cryptocurrency, nft, marketplace, defi, dapps, smartcontract, ethereum, decentralize, application, global, uae, dubai" />
          <meta name="robots" content="index, follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English"></meta>
        </Helmet>  
        <Header sticky={false}/>
        <section id="hero" className='bg-indigo-600 text-white w-full px-6 md:px-10 lg:px-40 py-10 md:py-20'>          
          <div class="flex flex-col md:flex-row gap-x-4">
            <div className="md:w-3/12 mb-2">
              <div class="flex flex-row md:flex-col gap-x-1 text-2xl uppercase">
              <span className='font-bold md:font-normal'>Explore</span>
              <span className="text-2xl lg:text-3xl font-bold text-indigo-50">Our Services</span>
              </div>
            </div>
            <div className='md:w-8/12 lg:w-8/12 xl:w-9/12 flex flex-col'>
              <h1 className='text-xl md:text-2xl lg:text-3xl font-regular md:font-semibold'>We deliver end-to-end solutions that are guaranteed to meet your needs, drive your performance, and give you that competitive edge.</h1>
              <div className="wow fadeInUp" data-wow-delay="1.2s">
              <Link to="./contact" target="_new" className="inline-block mt-5 bg-indigo-50 text-indigo-600 px-4 py-2 rounded-full">Let's Start</Link>
              </div>
            </div>
            
          </div>
        </section>

        <div className='mb-10 py-10 px-6 lg:px-16 xl:px-44 bg-white'>
          
          <div class="flex flex-col md:flex-row gap-x-4">
            
            <div className='flex flex-col'>
              
              <div className='md:mt-10 flex flex-col md:flex-row gap-x-20'>
                <div className='md:w-7/12'>
                  <h3 className='text-2xl md:text-3xl font-semibold mb-1'>Blockchain Consulting</h3>
                  <p className='font-semibold mb-4 text-lg md:text-xl'>Understand how blockchain solutions can transform your company’s future.</p>
                  <p className='text-justify md:text-lg'>Our blockchain consulting experts help you to identify the best blockchain solution for your organization that helps you to bring your idea to real world. You get the right knowledge and technical requirement to implement blockchain solutions into your organization. We develop a Proof of Concept to reflect which blockchain solutions will work in the real world, our approach validates the idea and give you confirmation that your business can go to market. We embrace the latest and proven blockchain, defi, and nft protocol to make sure the solution delivers the expectation. Blockchain is opening up a world of possibilities, but with this comes additional legal questions. We provide the support you need to navigate the novel space.</p>
                </div>
                <div  className='hidden md:block md:w-5/12'>
                  <img src='/assets/img/blockchain.jpg' />
                </div>
              </div>              

              <div className='mt-10 md:mt-20 flex flex-col md:flex-row gap-x-20'>
                <div  className='hidden md:block md:w-5/12'>
                  <img src='/assets/img/development.jpg' />
                </div>
                <div className='md:w-7/12'>
                  <h3 className='text-2xl md:text-3xl font-semibold mb-2'>Development</h3>
                  <p className='font-semibold mb-4 text-lg md:text-xl'>Customized blockchain platform solutions designed for your company’s unique needs.</p>
                  <p className='text-justify md:text-lg'>Our Blockchain development ensures you get the right technology your business’ needs within your budget. We take you through our design-thinking process, to determine your goals, understand your needs, and deliver this in line with your budget.We’re technology-neutral, which means we work across a wide range of different technologies. </p>
                </div>
              </div>

              <div className='mt-10 flex flex-col md:flex-row gap-x-20'>
                <div className='md:w-7/12'>
                  <h3 className='text-2xl md:text-3xl font-semibold mb-1'>Marketing</h3>
                  <p className='font-semibold mb-4 text-lg md:text-xl'>Develop a blockchain, Crypto & Defi marketing strategy to get your solution in front of the right audience.</p>
                  <p className='text-justify md:text-lg'>Marketing your product is always tricky. You need to know the right channels to use, the right network to tap into, and the right way to present your product to the world. As we are a 360-degree blockchain marketing agency, our team has the skills to create a tailored marketing strategy, whatever your budget allows for. Our blockchain marketing consultants will work with you and bring you along the journey, ensuring you understand how we’re positioning your project, which marketing channels we’re targeting, and why.</p>
                </div>
                <div  className='hidden md:block md:w-5/12'>
                  <img src='/assets/img/marketing.jpg' />
                </div>
              </div>    


              <div className='mt-10 md:mt-20 flex flex-col md:flex-row gap-x-20'>
                <div  className='hidden md:block md:w-5/12'>
                  <img src='/assets/img/smartcontract.jpg'/>
                </div>
                <div className='md:w-7/12'>
                  <h3 className='text-2xl md:text-3xl font-semibold mb-1'>Smart Contract</h3>
                  <p className='font-semibold mb-4 text-lg md:text-xl'>Build smart contracts to automate transactions, enhance trust, and eliminate third-party authentication.</p>
                  <p className='text-justify md:text-lg'>Smart contracts are built using blockchain technology. They work as a coded paper contract, with predefined conditions that cause an action to occur when these conditions are met. Essentially, they automate your contract, cutting out the need for human interaction.</p>
                </div>
              </div>

              <div className='mt-10 flex flex-col md:flex-row gap-x-20'>
                <div className='md:w-7/12'>
                  <h3 className='text-2xl md:text-3xl font-semibold mb-1'>Cryptocurrency</h3>
                  <p className='font-semibold mb-4 text-lg md:text-xl'>We’ll design the cryptocurrency solution that powers your business forward.</p>
                  <p className='text-justify md:text-lg'>Our expert team of cryptocurrency experts, designers, and engineers can work with you to determine how a cryptocurrency wallet or payment gateway solution will enable your business to adapt to a digital future.</p>
                </div>
                <div  className='hidden md:block md:w-5/12'>
                  <img src='/assets/img/cryptocurrency.jpg' />
                </div>
              </div> 


            </div>
          </div>
        </div>

        <div className='py-10 px-6 lg:px-16 xl:px-44 bg-gray-100'>
            <h2 className='text-2xl md:text-4xl font-bold mb-5'>Our working process</h2>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-5 md:gap-y-10'>
                <div className='bg-white p-10 flex flex-col border'>
                  <h3 className='text-xl md:text-2xl font-bold mb-4'>1. Consultation</h3>
                  <div>
                    <ul className='list list-disc md:text-lg'>
                        <li>We start with a design thinking workshop to ascertain your requirements</li>
                        <li>We will understand your requirement for customized blockchain platform solutions or marketing that need to be designed for your company’s unique needs.</li>
                        <li>This way we will identify your business’ needs and goals, and determine where blockchain will streamline your processes.</li>
                    </ul>
                  </div>
                </div>
                <div className='bg-white p-10 flex flex-col border'>
                  <h3 className='text-xl md:text-2xl font-bold mb-4'>2. Ideation</h3>
                  <div>
                  <ul className='list list-disc md:text-lg'>
                    <li>We will be designing and planning the blockchain architecture or marketing strategy systematically.</li>
                    <li>We determine which technology will be the best fit to meet your needs, and begin developing solutions to achieve this.</li>
                    <li>We propose marketing campaigns to meet your target looking to achieve.</li>
                    </ul>
                  </div>
                </div>
                <div className='bg-white p-10 flex flex-col border'>
                  <h3 className='text-xl md:text-2xl font-bold mb-4'>3. Execution</h3>
                  <div>
                  <ul className='list list-disc md:text-lg'>
                  <li>We will do extensive development, implementation and quality analysis over an agreed time period.</li>
                  <li>We start working on development/marketing from the ground up with your active involvement to make sure everything aligns with what you are looking for.</li>
                  <li>We will adjust every feedback we receive on the process.</li>
                  </ul>
                </div>        
                </div>
                <div className='bg-white p-10 flex flex-col border'>
                  <h3 className='text-xl md:text-2xl font-bold mb-4'>4. Delivery and Support</h3>
                  <div>
                    <ul className='list list-disc md:text-lg'>
                      <li>We will deliver the solution and provide constant support.</li>
                      <li>We will involve your business to keep listing what your customer is looking for, so we can take feedback and solve the issue they faced. </li>
                    </ul>
                  </div>
                </div>
            </div>            
        </div>

        <section className='py-10 md:py-20 px-6 lg:px-16 xl:px-40 bg-gray-50 border-t'>
            <h1 className="text-2xl md:text-3xl font-semibold">Industry we support</h1>    
            <div className='mt-10 grid grid-cols-4 md:grid-cols-6 xl:grid-cols-9 gap-x-16 gap-y-8 text-sm md:text-md'>    
                  <div className='flex flex-col items-center gap-y-3'>
                    <img src='/assets/img/icons/ecommerce.png' className='grayscale hover:grayscale-0 h-12 w-12 md:h-16 md:w-16'/>
                    <p className='text-center'>Ecommerce</p>
                  </div>
                  <div className='flex flex-col items-center gap-y-3'>
                    <img src='/assets/img/icons/logistics.png'  className='grayscale hover:grayscale-0 h-12 w-12 md:h-16 md:w-16'/>
                    <p className='text-center'>Logistic</p>
                  </div>
                  <div className='flex flex-col items-center gap-y-3'>
                    <img src='/assets/img/icons/finance.png'  className='grayscale hover:grayscale-0 h-12 w-12 md:h-16 md:w-16'/>
                    <p className='text-center'>Banking</p>
                  </div>
                  <div className='flex flex-col items-center gap-y-3'>
                    <img src='/assets/img/icons/healthcare.png'  className='grayscale hover:grayscale-0 h-12 w-12 md:h-16 md:w-16'/>
                    <p className='text-center'>Healthcare</p>
                  </div>                  
                  <div className='flex flex-col items-center gap-y-3'>
                    <img src='/assets/img/icons/education.png'  className='grayscale hover:grayscale-0 h-12 w-12 md:h-16 md:w-16'/>
                    <p className='text-center'>Education</p>
                  </div>
                  <div className='flex flex-col items-center gap-y-3'>
                    <img src='/assets/img/icons/payment.png'  className='grayscale hover:grayscale-0 h-12 w-12 md:h-16 md:w-16'/>
                    <p className='text-center'>Fintech</p>
                  </div>                  
                  <div className='flex flex-col items-center gap-y-3'>
                    <img src='/assets/img/icons/identity.png'  className='grayscale hover:grayscale-0 h-12 w-12 md:h-16 md:w-16'/>
                    <p className='text-center'>Identity Management </p>
                  </div>
                  <div className='flex flex-col items-center gap-y-3'>
                    <img src='/assets/img/icons/voting.png'  className='grayscale hover:grayscale-0 h-12 w-12 md:h-16 md:w-16'/>
                    <p className='text-center'>Voting</p>
                  </div>
                  <div className='flex flex-col items-center gap-y-3'>
                    <img src='/assets/img/icons/real-estate.png'  className='grayscale hover:grayscale-0 h-12 w-12 md:h-16 md:w-16'/>
                    <p className='text-center'>Realestate</p>
                  </div>
            </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}
