import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';


class index extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>                
                <Outlet />
            </>
        )
    }
}

export default index;