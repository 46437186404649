import React, { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet';



const Contact = () => {
    
    // const form = useRef();

    const [inputValues, setInputValues] = useState({
        name:'',
        subject:'',
        email:'',
        tel:'',
        message:''
    });

    //const [formData, setformData] = useState('');
    const [formErrors, setformErrors] = useState({});
    const [isFormValid, setisFormValid] = useState(false);
    //const [isSubmit, setIsSubmit] = useState(false);
    const [postSuccess, setpostSuccess] = useState(false);
    const [ApiCalled, setApiCalled] = useState(false);
    const [isLoading, setisLoading] = useState(false);

    //const [records, setRecords] = useState([]);


    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputValues({ ...inputValues, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        setpostSuccess(false);

        setisLoading(true);           
        
        const formValues = {...inputValues, id:new Date().getTime().toString()};     
        
        let formValid = validate(formValues);
        
        setisFormValid(formValid);
        
        //setIsSubmit(true);  
        
        //console.log(isFormValid);
        

        if(formValid){    

            await emailjs.sendForm('service_ynl1bpp', 'template_ctcb3id', e.target, 'user_IsJGkquxfQvOd2FFzTaVf')
            .then((result) => {

                setApiCalled(true);
                setpostSuccess(true);
                setInputValues({name:'',
                    subject:'',
                    email:'',
                    tel:'',
                    message:''}
                ); 
                
                setisLoading(false);                

            }, (error) => {
                setApiCalled(true);
                setpostSuccess(false);
                setisLoading(false);
            });   
       }   
       else {
        setisLoading(false);
       }         

            
    }

    useEffect(()=> {
        //console.log("formErrors " + Object.keys(formErrors).length);

        // async function fetchMyAPI(formData) {
                
        //     await emailjs.sendForm('service_ynl1bpp', 'template_ctcb3id', formData, 'user_IsJGkquxfQvOd2FFzTaVf')
        //     .then((result) => {
        //         console.log(result.text);
        //         setpostSuccess(true);
        //         console.log(postSuccess);
        //         // if(result.text=='Ok') {
        //         // }

        //     }, (error) => {
        //         console.log(error.text);
        //     });   
        // }

        // if(Object.keys(formErrors).length === 0 && isSubmit){                       
        //     fetchMyAPI(formData);            
        // }        

    },[formErrors]);

    const validate = (values) => {
        let errors = {};
        let formIsValid = true;

        if(!values.name) {
            errors.name = "Name is required!";
            formIsValid = false;
        }
        if(!values.subject) {
            errors.subject = "Subject is required!";
            formIsValid = false;
        }
        if(!values.tel) {
            errors.tel = "Telephone number is required!";
            formIsValid = false;
        }
        if(!values.email) {
            errors.email = "Email is required";
            formIsValid = false;
        }
        if(!values.message) {
            errors.message = "Message is required!";
            formIsValid = false;
        }

        setformErrors(errors);

        return formIsValid;

    }

    return (
        <>
             <Helmet>
                <title>Exploreby Technology:: Contact</title>
                <meta name="title" content="Exploreby Technology Contact" />
                <meta name="description" content="Let us contact you, To help you to understand your requirement." />
                <meta name="keywords" content="exploreby, blockchain, information, technology, development, IT services, cryptocurrency, nft, marketplace, defi, dapps, smartcontract, ethereum, decentralize, application, global, IT products, uae, dubai" />
                <meta name="robots" content="index, follow" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="English"></meta>
             </Helmet> 
            <Header sticky={false}/>
            <div className='px-10 md:px-10 md:max-w-6xl m-auto my-20'>
                <div className="flex flex-col-reverse md:flex-row">
                    <div className="w-full md:w-6/12 mt-10 md:mt-0 p-6 md:p-0 border md:border-0 bg-white md:bg-gray-50">
                        <h3 className="text-2xl text-gray-900 font-semibold">Contact Us</h3>
                        <p>Email : info@explorebytech.com</p>
                        <div className="flex flex-col mt-10">
                            <div>
                                <ul>
                                    <li class="font-bold text-lg py-1">Head Office</li>
                                    <li>Financial Center, Dubai</li>
                                    <li class="font-medium">United Arab Emirates</li>
                                </ul>
                            </div>
                            <div className='mt-10'>
                                <ul>
                                    <li class="font-bold text-lg py-1">Development Office</li>
                                    <li>Kathmandu</li>
                                    <li class="font-medium">Nepal</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className="w-full md:w-6/12">
                    <form onSubmit={handleSubmit} method="post" className="form relative">      
                        <h3 className="text-2xl text-gray-900 font-semibold">Let us contact you!</h3>
                        <p className="text-gray-600"> To help you to understand your requirement.</p>
                        {
                        ApiCalled && isFormValid ? (
                            postSuccess ? (
                            <div className='mt-5 text-sm font-medium border px-4 py-3 bg-green-100 border-green-400 text-green-900 rounded'>
                                Thank you for contacting us. We will get back to you soon, Thank you!
                            </div>
                            ):(
                                <div className='mt-5 text-sm font-medium border px-4 py-3 bg-red-100 border-red-400 text-red-900 rounded'>
                                We apologize for the inconvenience, please contact direct to admin@exploreby.com. Thank you!
                                </div>
                            )
                        ):('')
                        }
                        

                        <div className="flex space-x-5 mt-8">
                            <div className="w-1/2">
                                <input type="text" name="name" id="name" 
                                value={inputValues.name}
                                onChange={handleChange} 
                                placeholder="Your Name" className={`border p-2 w-full${formErrors.name ? ' border-red-600' : ''}`} />
                                <p className='text-red-600 text-sm py-1'>{formErrors.name}</p>
                            </div>
                            <div className="w-1/2">
                                <input type="tel" name="tel" id="tel" 
                                value={inputValues.tel}
                                onChange={handleChange} 
                                placeholder="Your Number" className="border p-2 w-full" />
                                <p className='text-red-600 text-sm py-1'>{formErrors.tel}</p>
                            </div>
                        </div>
                        <div>
                            <input type="text" name="subject" id="subject" 
                                value={inputValues.subject}
                                onChange={handleChange} 
                                placeholder="Subject" className="border p-2 w-full mt-2" />
                                <p className='text-red-600 text-sm py-1'>{formErrors.subject}</p>
                        </div>
                        <div>
                            <input type="email" name="email" id="email" 
                                value={inputValues.email}
                                onChange={handleChange} 
                                placeholder="Your Email" className="border p-2 w-full mt-2" />
                                <p className='text-red-600 text-sm py-1'>{formErrors.email}</p>
                        </div>
                        <div>
                            <textarea name="message" id="message" 
                                value={inputValues.message} 
                                onChange={handleChange} 
                                cols="10" rows="3" placeholder="Tell us about your requirement" className="border p-2 mt-2 w-full" />                                   
                            <p className='text-red-600 text-sm py-1'>{formErrors.message}</p>
                        </div>                  
                        <button type="submit" name="submit" className="w-full mt-6 bg-blue-600 hover:bg-blue-500 text-white font-semibold p-3 disabled:bg-blue-300" disabled={isLoading}>Submit</button>
                    </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Contact;
